<template>
  <div>
    <p>设置时间<span>(请先选择结束时间)</span></p>
    <el-form :model="form"
             label-width="80px">
      <div class="row">
        <el-form-item :label="`第${strList[index]}节课:`"
                      v-for="(item,index) in timeList"
                      :key="index">
          <!-- {{getOptions(index)}} -->
          <el-time-picker is-range
                          v-model="timeList[index]"
                          format="HH:mm"
                          @blur="blur(index)"
                          :disabled="getStatus(index)"
                          style="width:100%"
                          value-format="HH:mm"
                          range-separator="-"
                          start-placeholder="开始"
                          end-placeholder="结束"
                          placeholder="时间范围">
          </el-time-picker>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      form: {},
      timeList: ['', '', '', '', '', '', '', '', ''],
      strList: ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二']
    }
  },
  methods: {
    getOptions (index) {
      if (index == 0) {
        return {
          selectableRange: '05:30 - 23:59',
        }
      }
      let obj = {
        start: this.timeList[index - 1][1] || '',
      }
      return obj
    },
    blur (index) {
      if (index == 0) {
        return
      }
      var oldH = Number(this.timeList[index - 1][1].slice(0, 2))
      var oldM = Number(this.timeList[index - 1][1].slice(3, 5))
      var newH = this.timeList[index][0].slice(0, 2)
      var newM = this.timeList[index][0].slice(3, 5)

      if (oldH > newH || oldH == newH && oldM >= newM) {
        this.timeList[index] = ''
        this.$set(this.timeList, index, '')
      }
    },
    getStatus (index) {
      if (index == 0) return false
      if (this.timeList[index - 1]) {
        return false
      }
      return true
    },

  },
}
</script>

<style lang="scss" scoped>
p {
  font-size: 16px;
  font-weight: bold;
  color: #000000;
  line-height: 40px;
  span {
    font-size: 12px;
    color: red;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  .el-form-item {
    width: 20%;
    min-width: 270px;
    [data-v-fae5bece] ::v-deep.el-form-item__label {
      font-size: 16px !important;
      font-weight: bold;
      color: #666666;
    }
  }
}
</style>